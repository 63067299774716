import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import axios from 'axios';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import Web3 from 'web3';

// import { fetchData } from '../redux/data/dataActions';
// import { connect } from '../redux/blockchain/blockchainActions';
import SmartContractABI from '../contracts/ABI.json';

const INFURA_ID = '460f40a260564ac4a4f4b3fffb032dad';

const {
  REACT_APP_CONTRACT_ADDRESS,
  REACT_APP_NETWORK_ID,
  REACT_APP_NETWORK,
  REACT_APP_API_URL,
} = process.env;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  }
};

let web3Modal = new Web3Modal({
  network: 'mainnet', // optional
  cacheProvider: true,
  providerOptions, // required
});

export default function Mint() {
  const [count, setCount] = useState(1);
  const [whitelist, setwhitelist] = useState([]);
  const [fetching, setFetching] = useState(false);
  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();
  const {
    address,
    provider,
    chainId,
    data,
    smartContract,
    web3Provider,
    loading: isLoading,
  } = wallet;

  const loading = fetching || isLoading;

  const connect = useCallback(async function () {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    const address = await web3.eth.getAccounts();
    const network = await web3.eth.getChainId();
    const SmartContractObj = new web3.eth.Contract(
      SmartContractABI,
      REACT_APP_CONTRACT_ADDRESS
    );
    dispatch({
      type: 'SET_WEB3_PROVIDER',
      web3Provider: web3,
      provider,
      address: address[0],
      chainId: network,
      smartContract: SmartContractObj,
    });
  }, []);

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect();
      }
      dispatch({
        type: 'RESET_WEB3_PROVIDER',
      });
    },
    [provider]
  );

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connect();
    }
  }, [connect]);

  useEffect(() => {
    if (chainId && chainId !== Number(REACT_APP_NETWORK_ID)) {
      toast.error(`Change the network to ${REACT_APP_NETWORK}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
  }, [chainId]);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = accounts => {
        // eslint-disable-next-line no-console
        console.log('accountsChanged', accounts);
        dispatch({
          type: 'SET_ADDRESS',
          address: accounts[0],
        });
      };

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = _hexChainId => {
        window.location.reload();
      };

      const handleDisconnect = error => {
        // eslint-disable-next-line no-console
        console.log('disconnect', error);
        disconnect();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider, disconnect]);

  const getWhitelistedUsers = async () => {
    try {
      setFetching(true);
      const response = await axios.get(REACT_APP_API_URL);
      const data = response.data.data;
      setwhitelist(data);
      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
    }
  };

  // useEffect(() => {
  //   getWhitelistedUsers();
  // }, []);

  const getData = async () => {
    try {
      console.log('called', smartContract);
      if (!!smartContract) {
        const web3 = web3Provider || new Web3(provider);
        let totalSupply = await smartContract.methods
          .totalSupply()
          .call();
        let cost = await smartContract.methods.cost().call();
        let maxSupply = await smartContract.methods.maxSupply().call();
        let presaleActive = await smartContract.methods.presaleActive().call();
        let saleActive = await smartContract.methods.saleActive().call();
        let maxMintAmount = await smartContract.methods
        .maxMintAmountPerTx()
        .call();
        let ownerAddr = await smartContract.methods.owner().call();
        const price = web3.utils.fromWei(cost, 'ether');
        console.log({
          totalSupply,
          maxSupply,
          maxMintAmount,
          cost: price,
          owner: ownerAddr,
          presaleActive,
          saleActive,
        });
        dispatch({
          type: 'SET_DATA',
          data: {
            totalSupply,
            maxSupply,
            maxMintAmount,
            cost: price,
            owner: ownerAddr,
            presaleActive,
            saleActive,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (smartContract) {
      getData();
    }
  }, [smartContract]);

   const claimNFTs = () => {
    if (count <= 0) {
      return;
    }
     
     if (!smartContract) {
       return;
     }

     const web3 = web3Provider || new Web3(provider);

     toast.info('Preparing your NFT...');
     const actualCost = Number(data.cost * count).toFixed(3);
     console.log(actualCost);
     const value = web3.utils.toWei(actualCost.toString(), 'ether');
     console.log(value);
     smartContract.methods
       .mint(count)
       .estimateGas({ from: address, value }).then((gas) => {
        smartContract.methods
          .mint(count)
          .send({
            gas,
            to: REACT_APP_CONTRACT_ADDRESS,
            from: address,
            value,
          })
          .once('error', err => {
            console.log(err);
            toast.error('It seems the transaction was cancelled.');
          })
          .then(receipt => {
            toast.success('Woohoo! NFT minted successfully!');
            getData();
          });
       }).catch(err => {
         if (err.message.includes('insufficient funds')) {
           toast.error('Insufficient Funds');
         } else if (
           err.message.includes('Not a valid leaf in the Merkle tree')
         ) {
           toast.error('Not a whitelist user');
         } else {
           const formatedError = err.message.split('{')[0];
           if (formatedError.includes('execution reverted:')) {
             toast.error(formatedError.split('execution reverted:')[1]);
           } else {
             toast.error(formatedError);
           }
         }
       });
    
   };
  
   const {
     totalSupply,
     cost,
     maxSupply,
     maxMintAmount,
     presaleActive,
     saleActive,
   } = data;

  return (
    <Flex
      backgroundImage={`${process.env.PUBLIC_URL}/hom1.png`}
      backgroundPosition="center"
      backgroundSize="cover"
      h="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        flexDirection="column"
        maxW="800px"
        className="mint-box"
        w="100%"
        justifyContent="center"
        alignItems="center"
        minH="400px"
        borderRadius="5px"
      >
        <Heading textAlign="center" color="white">
          MINT META APE FIGHT CLUB
        </Heading>
        <Text
          color="white"
          fontSize="3xl"
          my="20px"
        >{`${totalSupply}/${maxSupply} minted at ${cost} ETH`}</Text>
        <Flex>
          <Flex
            as="button"
            justifyContent="center"
            alignItems="center"
            w="50px"
            h="50px"
            bg="white"
            onClick={() => {
              if (count > 1) {
                setCount(count - 1);
              }
            }}
          >
            <AiOutlineMinus size="30" />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            w="200px"
            h="50px"
            borderWidth="2px"
          >
            <Text color="white" fontSize="3xl">
              {count}
            </Text>
          </Flex>
          <Flex
            as="button"
            justifyContent="center"
            alignItems="center"
            w="50px"
            h="50px"
            bg="white"
            onClick={() => {
              if (count < maxMintAmount) {
                setCount(count + 1);
              }
            }}
          >
            <AiOutlinePlus size="30" />
          </Flex>
        </Flex>
        <Flex
          as="button"
          bg="black"
          w="300px"
          h="60px"
          justifyContent="center"
          alignItems="center"
          mt="30px"
          borderRadius="5px"
          onClick={!address ? connect : claimNFTs}
        >
          <Text color="white" fontSize="2xl">
            {loading
              ? 'Please Wait...'
              : !address
              ? 'Connect Wallet'
              : `MINT ${count} NOW`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
