import React from 'react';
import { ChakraProvider, Box, theme, extendTheme } from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Fonts from './Fonts';
import { Main, Breeading, Staking, P2EGame, Mint } from './pages';
import { Header, Footer } from './components';


const extendedTheme = extendTheme({
  ...theme,
  fonts: {
    heading: 'Pixel-Western Regular',
    body: 'Pixel-Western Regular',
  },
  colors: {
    ...theme.colors,
    bg: '#150e29',
    bgLight: '#efeee8',
    bgDark: '#e8e7df',
    lightBlack: '#181817',
    lightOrange: '#feb64d',
    faq: '#58805a',
  },
});

function App() {
  return (
    <ChakraProvider theme={extendedTheme}>
      <Fonts />
      <Box minH="100vh" bg="bg">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" exact element={<Main />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/breading" element={<Breeading />} />
            <Route path="/staking" element={<Staking />} />
            <Route path="/p2egame" element={<P2EGame />} />
          </Routes>
          <Footer />
        </BrowserRouter>
        <ToastContainer />
      </Box>
    </ChakraProvider>
  );
}

export default App;
