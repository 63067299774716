import { Flex,Image, Heading } from '@chakra-ui/react';
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'

export default function Staking() {
  return (
    <Flex
     maxW="1200px"
   mx="auto"
   w="100%"
   py="50px"
    
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading mt="50px" mb={{base:'0',md:'40px'}} fontSize={{ base: '3xl', md: '7xl' }} color="white">
        Staking
      </Heading>
      <Flex mb="20px" flexDirection={{base:'column', md: 'row'}} maxW="1100px" mx="auto" justifyContent="center" alignItems="center">
        <Flex maxW="800px" color="white" alignItems="right" flexDirection="column">
          <Heading textAlign={{base:'center', md:'left'}} mb="20px" fontSize={{ base: '2xl', md: '3xl' }} >
            Every Ape is generating 10 MAFC Tokens (to be announced) per day for
            a period of 10 years. These can be used to for:
          </Heading>
          <UnorderedList px={{ base: '60px', md: 0 }} fontWeight="bold" fontSize="20px">
  <ListItem>Breeding</ListItem>
  <ListItem>P2E Game</ListItem>
  <ListItem>Fighting</ListItem>
  <ListItem>Betting</ListItem>
  <ListItem>Training</ListItem>
  <ListItem>Customize your Ape</ListItem>
</UnorderedList>
 <Heading textAlign={{base:'center', md:'left'}} mt="20px" fontSize={{ base: '2xl', md: '3xl' }} >
 Amounts to be announced…
          </Heading>
        </Flex>
        <Image mt={{base:'20px', md: 0}} maxW={{base:'350px', md:'400px'}} w="100%" src={`${process.env.PUBLIC_URL}/1.png`}/>
      </Flex>
    </Flex>
  );
}
