import {
  Box,
  Heading,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import Faq from 'react-faq-component';

const data = [
    {
      title: 'What is the cost of a MAFC NFT?',
      content: 'The mint price will be announced closer to mint date.',
    },
    {
      title: 'Where will I be able to view my NFT?',
      content: 'As soon as you have minted your own NFT you will be able to view them on Opensea.      ',
    },
    {
      title: 'Will there be a Presale?',
      content: 'Yes, we will be having a presale 1 day before the public sale. Join our Discord for Info.      ',
    },
    {
      title: 'Are there royalties for holders?',
      content:
        'Yes, all holders will receive their share of 25% royalties to be paid monthly.',
    },
    {
      title: 'What can I do with my NFT?      ',
      content:
        'Owning a MAFC NFT comes with full copyright claims 100% ownership. Once staking and breeding functions have opened you will be able to earn passive income as well as take part in Fight Nights within the Metaverse.        ',
    },
    {
      title: 'What is the minimum SPA?',
      content:
        'This is the minimum price we are aiming to sell the NFTs after minting is finish… Members will have to option to vote and have their inputs on what would be the best price.',
    },
    {
      title: 'Who owns the NFT?',
      content:
        'MAFC owns all NFTs and its copyrights. Once they are minted they are then 100% owned by the new owner. All buyers/minters has 100% ownership on their NFT and its copyright.',
    },
    
  ];

const config = {
  animate: true,
  
};

export default function FAQ() {
  const styles = {
    bgColor: 'transparent',
    rowTitleColor: '#fff',
    rowContentColor: '#fff',
    rowTitleTextSize: 'large',
  };
  return (
    <Flex
      minH="100vh"
      py="30"
      bg="bg"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      id="faq"
    >
      <Heading
        color="white"
        textTransform="uppercase"
        mt="90px"
        fontSize={{ base: '25px', md: '90px' }}
        fontWeight="bold"
        mb="40px"
        textAlign="center"
      >
        FAQ'S
      </Heading>
     
      <Box  maxW="1100px"  px={{ base: '20px', md: 0 }} w="100%">
        {/* <Faq data={data} styles={styles} config={config} /> */}
        <Accordion  w="100%" allowMultiple allowToggle>
          {data.map((faq, index) => (
            <AccordionItem bg="faq" key={index} mb="30px"  borderRadius="30px" borderColor="faq" borderWidth="1px">
              <h2>
                <AccordionButton>
                  <Box flex="1" py="15px" px="20px"  textAlign="left" color="white" fontSize="xl">
                    {faq.title}
                  </Box>
                  <AccordionIcon  color="white" fontSize="2xl" />
                </AccordionButton>
              </h2>
              <AccordionPanel letterSpacing="3px" pb={4} color="white" fontSize="lg">
                {faq.content}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Flex>
  );
}
