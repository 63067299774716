import { Flex,Image, Heading } from '@chakra-ui/react';

export default function Breeading() {
  return (
    <Flex
      // h="100vh"
      flexDirection="column"
   maxW="1200px"
   mx="auto"
   w="100%"
   py="50px"
      justifyContent="center"
      alignItems="center"
    >
      <Heading mt="40px" mb="40px" fontSize={{ base: '3xl', md: '7xl' }} color="white">
      Breeding
      </Heading>
      <Flex mb="40px" flexDirection={{base:'column', md:'row'}} maxW="1200px" px="20px" mx="auto" justifyContent="center" alignItems="center">
        <Flex maxW="800px" color="white" alignItems="right" flexDirection="column">
          <Heading mb="20px" fontSize={{ base: '2xl', md: '2xl' }} >
          To breed you will need one Male and one Female Ape in your wallet.
          </Heading>
          <Heading mb="20px" fontSize={{ base: '2xl', md: '2xl' }} >
          Once the breeding is finished, you will immediately receive your Baby in your wallet, but it will take 5 weeks for the baby to reveal. The Baby will have a combination of both parents most rare traits…
          </Heading>
          <Heading mb="20px" fontSize={{ base: '2xl', md: '2xl' }} >
          Both parents will also have a 10-week cooldown period where they will not be able to breed until this period is finished. 
          </Heading>
          <Heading mb="20px" fontSize={{ base: '2xl', md: '2xl' }} >
          You will be able to look up all the Apes to see if they are in cooldown or ready to breed. This is to ensure that you do not buy an Ape that is not eligible to breed when you want to breed.
          </Heading>
       
 
        </Flex>
        <Image maxW="400px" w="100%" src={`${process.env.PUBLIC_URL}/2.png`}/>
      </Flex>
    </Flex>
  );
}
