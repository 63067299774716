import {
  Box,
  Text,
  Flex,
  Heading,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default function Roadmap() {
  const arrowStyle = {
    borderRightColor: 'transparent',
  };
  const contentStyle = {
    background: 'transparent',
    boxShadow: 'none',
  };
  const iconStyle = {
    background: 'rgb(88 128 90)',
    width: 30,
    height: 30,
    marginLeft: 5,
    boxShadow: 'none',
    top: 15,
  };
  return (
    <>
      <Box fontWeight="bold" maxW="1200px" mx="auto" py="30px" id="roadmap">
        <Heading
          color="white"
          fontSize={{ base: '5xl', md: '9xl' }}
          fontWeight="bold"
          my="50px"
          textAlign="center"
        >
          Launch Roadmap
        </Heading>
        <VerticalTimeline layout="1-column" className="timeline">
          <VerticalTimelineElement
            className="timeline-box left"
            contentArrowStyle={arrowStyle}
            contentStyle={contentStyle}
            iconStyle={iconStyle}
          >
            <Heading className="roadmap-heading" color="white">
              0% - Welcome to AFC
            </Heading>
            <Text
              textAlign="justify"
              maxW="900px"
              letterSpacing="4px"
              color="white"
            >
              To celebrate 5000 Discord members, we are giving away 10 WhiteList
              spots to active discord members. Ape Fight Club members will have
              to work together to promote the project on all socials.
            </Text>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="timeline-box left"
            contentArrowStyle={arrowStyle}
            contentStyle={contentStyle}
            iconStyle={iconStyle}
          >
            <Heading className="roadmap-heading" color="white">
              25%
            </Heading>
            <Text
              textAlign="justify"
              maxW="900px"
              letterSpacing="4px"
              color="white"
            >
              We have now reached 20 000 Discord members and growing rapidly.
              After giving away another 25 WhiteList spots to members our
              WhiteList is now full with 2000 members. Pre Sale mint is live for
              our WhiteList Members for 24 hours. Public Mint is live for all,
              put your gloves on and fight the crowd to mint your Ape.
            </Text>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="timeline-box left"
            contentArrowStyle={arrowStyle}
            contentStyle={contentStyle}
            iconStyle={iconStyle}
          >
            <Heading className="roadmap-heading" color="white">
              50%
            </Heading>
            <Text
              textAlign="justify"
              maxW="900px"
              letterSpacing="4px"
              color="white"
            >
              We have reached a remarkable milestone, all CHAMPION holders will
              receive a trip to Abu Dhabi Fight Island/ Las Vegas. Holders will
              also start receiving their percentage of royalties on a monthly
              basis.
            </Text>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="timeline-box left"
            contentArrowStyle={arrowStyle}
            contentStyle={contentStyle}
            iconStyle={iconStyle}
          >
            <Heading className="roadmap-heading" color="white">
              75%
            </Heading>
            <Text
              textAlign="justify"
              maxW="900px"
              letterSpacing="4px"
              color="white"
            >
              Diamond hands will be rewarded for their loyalty towards the
              toughest Apes in the Metaverse. Weekly giveaways will be held only
              for holders on the discord.
            </Text>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="timeline-box left"
            contentArrowStyle={arrowStyle}
            contentStyle={contentStyle}
            iconStyle={iconStyle}
          >
            <Heading className="roadmap-heading" color="white">
              100%
            </Heading>
            <Text
              textAlign="justify"
              maxW="900px"
              letterSpacing="4px"
              color="white"
            >
              We have sold out all Apes. The fun starts NOW. P2E Game is
              introduced to the community along with Breeding and Staking. Train
              your Ape and fight for the Belt to become a CHAMPION. One lucky
              CHAMPION holder will win an APARTMENT in Dubai.
            </Text>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Box>
      <Box maxW="1200px" px="10px" mx="auto" py="30px">
        <Heading
          color="white"
          fontSize={{ base: '4xl', md: '9xl' }}
          fontWeight="bold"
          mt="50px"
          textAlign="center"
        >
          DEVELOPMENT
        </Heading>
        <Heading
          color="white"
          fontSize={{ base: '4xl', md: '9xl' }}
          fontWeight="bold"
          mb="50px"
          textAlign="center"
        >
          ROADMAP
        </Heading>
        <Text
          color="white"
          fontSize="20px"
          textAlign="center"
          letterSpacing="5px"
          mb="20px"
        >
          Our goal is to create a positive time for our holders in combination
          to making your investment profitable. We are working on the roadmap
          below but we are adding new features based on our holders' opinion. We
          listen to our holders and hold voting polls to get their ideas in our
          Discord as their opinions matter to us.
        </Text>

        <Box
          maxW="1200px"
          mx="auto"
          w="100%"
          borderBottomWidth="1px"
          px="20px"
          py="20px"
          alignItems="center"
        >
          <Heading mt="50px" mb="20px" fontWeight="bold" color="white">
            Royalties
          </Heading>
          <Text color="white" fontSize="20px" letterSpacing="5px" mb="20px">
            MAFC is a longterm project, we are not a pump and dump project. We
            are pumping 75% of all royalties from secondary sales market back
            into the project as follows:
          </Text>

          <Flex
            maxW="300px"
            mx="auto"
            w="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box fontSize="20px" color="white" fontWeight="bold">
              <Text mb="20px">25%</Text>
              <Text mb="20px">25%</Text>
              <Text mb="20px">25%</Text>
            </Box>

            <Box fontSize="20px" color="white" fontWeight="bold">
              <Text mb="20px">Marketing & Development</Text>
              <Text mb="20px">Holders</Text>
              <Text mb="20px">Prize money</Text>
            </Box>
          </Flex>
          <Text color="white" fontSize="20px" letterSpacing="5px" mb="20px">
            We believe by giving back to all holders it shows our appreciation,
            it will drive our floor price up as all holders will receive income
            accordingly.
          </Text>
        </Box>

        {/* <Box
          maxW="1200px"
          mx="auto"
          w="100%"
          borderBottomWidth="1px"
          px="20px"
          py="20px"
          alignItems="center"
        >
          <Heading mt="50px" mb="20px" fontWeight="bold" color="white">
            Staking
          </Heading>
          <Text color="white" fontSize="20px" letterSpacing="5px" mb="20px">
            All holders will receive daily TOKENS for every MAFC NFT they hold.
            These tokens can be used to make changes to your Ape, whether it is
            its name, description, use it to train your Ape in the metaverse to
            become a better fighter or to breed.
          </Text>

          <Heading
            textAlign="center"
            mt="50px"
            mb="20px"
            fontWeight="bold"
            color="white"
          >
            Receive 5 tokens per day
          </Heading>

          <Flex
            maxW="300px"
            mx="auto"
            w="100%"
            alignItems="center"
            justifyContent="space-around"
          >
            <Box fontSize="20px" color="white" fontWeight="bold">
              <Text mb="20px">Rename</Text>
              <Text mb="20px">Train</Text>
              <Text mb="20px">Description</Text>
              <Text mb="20px">Fight</Text>
              <Text mb="20px">Breed</Text>
            </Box>

            <Box fontSize="20px" color="white" fontWeight="bold">
              <Text mb="20px">200</Text>
              <Text mb="20px">3</Text>
              <Text mb="20px">300</Text>
              <Text mb="20px">50</Text>
              <Text mb="20px">1000</Text>
            </Box>
          </Flex>
          <Text
            mt="20px"
            fontWeight="bold"
            color="white"
            textAlign="center"
            fontSize="20px"
            letterSpacing="5px"
            mb="20px"
          >
            must hold male and female fighter
          </Text>
        </Box> */}
{/* 
        <Box
          maxW="1200px"
          mx="auto"
          w="100%"
          borderBottomWidth="1px"
          px="20px"
          py="20px"
          alignItems="center"
        >
          <Heading
            mt="50px"
            textAlign="center"
            mb="20px"
            fontWeight="bold"
            color="white"
          >
            Buy Bars :
          </Heading>

          <Flex
            maxW="300px"
            mx="auto"
            w="100%"
            alignItems="center"
            justifyContent="space-around"
          >
            <Box fontSize="20px" color="white" fontWeight="bold">
              <Text mb="20px">5 bars</Text>
              <Text mb="20px">50 bars</Text>
              <Text mb="20px">150 bars</Text>
            </Box>

            <Box fontSize="20px" color="white" fontWeight="bold">
              <Text mb="20px">$5 </Text>
              <Text mb="20px">$45</Text>
              <Text mb="20px">$125 </Text>
            </Box>
          </Flex>
          <Text
            mt="20px"
            fontWeight="bold"
            color="white"
            textAlign="center"
            fontSize="20px"
            letterSpacing="5px"
            mb="20px"
          >
            can only buy once a week
          </Text>
        </Box> */}

        <Box
          maxW="1200px"
          mx="auto"
          w="100%"
          borderBottomWidth="1px"
          px="20px"
          py="20px"
          alignItems="center"
        >
          <Heading mt="20px" mb="20px" fontWeight="bold" color="white">
            Breeding
          </Heading>
          <Text color="white" fontSize="20px" letterSpacing="5px" mb="20px">
            If you hold a Male and Female Ape you will be able to breed with
            them and create baby MAFC. The Baby will consist of its parents most
            rare traits.
          </Text>
        </Box>

        <Box
          maxW="1200px"
          mx="auto"
          w="100%"
          borderBottomWidth="1px"
          px="20px"
          py="20px"
          alignItems="center"
        >
          <Heading mt="20px" mb="20px" fontWeight="bold" color="white">
            Play to Earn Game
          </Heading>
          <Text color="white" fontSize="20px" letterSpacing="5px" mb="20px">
            We will launch a play to earn game in the metaverse where you will
            be able to fight other holders and become the ultimate fighting ape.
          </Text>
          <Text color="white" fontSize="20px" letterSpacing="5px" mb="20px">
            We will also host tournament where you can fight and earn TOKENS and
            money.
          </Text>
          <Text color="white" fontSize="20px" letterSpacing="5px" mb="20px">
            Live broadcasted fight nights held in the Metaverse in our our own
            Arena.
          </Text>
        </Box>

        <Box
          maxW="1200px"
          mx="auto"
          w="100%"
          borderBottomWidth="1px"
          px="20px"
          py="20px"
          alignItems="center"
        >
          <Heading mt="20px" mb="20px" fontWeight="bold" color="white">
            Metaverse
          </Heading>
          <Text color="white" fontSize="20px" letterSpacing="5px" mb="20px">
            We will have our own training facility AND Arena in the Metaverse
            where all apes can train daily and where the Fight Nights will take
            place.
          </Text>
          <Text color="white" fontSize="20px" letterSpacing="5px" mb="20px">
            You will be able to use your character as an avatar in the
            Metaverse.
          </Text>
        </Box>
      </Box>
    </>
  );
}
