import { Flex, Text, Heading } from '@chakra-ui/react';
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';

export default function P2EGame() {
  return (
    <Flex
      py="20px"
      color="white"
      maxW="1200px"
      mx="auto"
      w="100%"
      px="20px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading
        mt="80px"
        textAlign="center"
        mb="20px"
        fontSize={{ base: '3xl', md: '7xl' }}
        color="white"
      >
        P2E Game
      </Heading>
      <Heading
        textAlign="center"
        mb="20px"
        fontSize={{ base: '2xl', md: '2xl' }}
      >
        In our game you will be able to fight with your Ape.
      </Heading>
      <Heading
        textAlign="center"
        mb="20px"
        fontSize={{ base: '2xl', md: '2xl' }}
      >
        You will be able to challenge other fighters, place bets of fights and
        there will be a ranking system….
      </Heading>
      <Heading
        textAlign="center"
        mb="20px"
        fontSize={{ base: '2xl', md: '2xl' }}
      >
        Every Ape has a specific color background. These backgrounds will serve
        as ‘weight classes’, you will only be able to fight Apes with the same
        background with the following exceptions…
      </Heading>
      <Heading
        textAlign="center"
        mb="20px"
        fontSize={{ base: '2xl', md: '2xl' }}
      >
        • There are a few rare Apes with 2-color backgrounds, this means that
        they are eligible to fight in both colors/weights…
      </Heading>
      <Heading
        textAlign="center"
        mb="20px"
        fontSize={{ base: '2xl', md: '2xl' }}
      >
        • All CHAMPIONS have different backgrounds, their weight class is
        reflected in their belt color… the strap of their belt is the same color
        as the backgrounds…
      </Heading>
      <Heading
        textAlign="center"
        mb="20px"
        fontSize={{ base: '2xl', md: '2xl' }}
      >
        The lucky few who will mint a champion will forever be the OG champion,
        only in the game they will loose their belt to the challenger etc..
      </Heading>
      <Heading
        textAlign="center"
        mb="20px"
        fontSize={{ base: '2xl', md: '2xl' }}
      >
        You will be able to train the following aspects of your Ape using the
        MAFC tokens
      </Heading>
      <Flex
        mb="50px"
        py="20px"
        w="100%"
        alignItems="center"
        maxW="600px"
        mx="auto"
        justifyContent="space-around"
      >
        {' '}
        <UnorderedList fontWeight="bold" fontSize="30px">
          <ListItem>Striking</ListItem>
          <ListItem>Kicking</ListItem>
          <ListItem>Grappling</ListItem>
        </UnorderedList>
        <UnorderedList fontWeight="bold" fontSize="30px">
          <ListItem>Endurance</ListItem>
          <ListItem>Speed</ListItem>
          <ListItem>Power</ListItem>
        </UnorderedList>
      </Flex>
    </Flex>
  );
}
