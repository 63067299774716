import { Box, Flex,Heading,Image, Link, Text } from '@chakra-ui/react';

import { FaTwitter,FaInstagram  } from 'react-icons/fa';
import { SiDiscord } from 'react-icons/si';
import { AiOutlineInstagram } from 'react-icons/ai';

export default function Footer() {
  return (
    <Box bg="faq" w="100%"   py="20px" px="20px">
      <Flex
        py="10px"
        mt="140px"
        color="white"
        flexDirection="column"
        alignItems="center"
      >
        <Heading
          mb="30px"
          fontSize={{ base: '30px', md: '50px' }}
          fontWeight="bold"
        >
          Meta Ape Fight Club
        </Heading>
        <Text fontSize="20px" mb="30px" letterSpacing="3px">
          All Rights Reserved 2021
        </Text>
        <Text fontSize="20px" mb="30px" letterSpacing="3px">
          Terms and conditions
        </Text>
        <Text fontSize="20px" mb="30px" letterSpacing="3px" >
          contract address
        </Text>
        <Link isExternal href="https://raritysniper.com/"><Image maxW="120px" src={`${process.env.PUBLIC_URL}/logo1.png`} /></Link>
        <Flex
          py="20px"
          mt="30px"
          px="10px"
          maxW="350px"
          w="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link
            href="https://discord.gg/r4EbQ57mXc"
            isExternal
            textAlign="center"
            color="white"
          >
            <SiDiscord size="60" />
          </Link>
          <Link
            href="https://www.twitter.com/"
            isExternal
            textAlign="center"
            color="white"
          >
            <FaTwitter size="60" />
          </Link>
          <Link
            href="https://instagram.com/meta_apefightclub_nft?utm_medium=copy_link"
            isExternal
            textAlign="center"
            color="white"
          >
            <FaInstagram size="60" />
          </Link>
          <Link
            href="https://opensea.io/Meta_Ape_Fight_Club_Official"
            isExternal
            textAlign="center"
            color="white"
          >
            <Image maxW="60px" src={`${process.env.PUBLIC_URL}/open.png`} />
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}
