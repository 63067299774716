import { Box, Heading, Image, Text } from '@chakra-ui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const { PUBLIC_URL } = process.env;

const images = new Array(18).fill().map((_, i) => i);

export default function Gallery() {
    const settings = {
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 8000,
      pauseOnHover: false,
      cssEase: 'linear',
      infinite: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 8000,
            pauseOnHover: false,
            cssEase: 'linear',
            infinite: true,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 8000,
            pauseOnHover: false,
            cssEase: 'linear',
            infinite: true,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 8000,
            pauseOnHover: false,
            cssEase: 'linear',
            infinite: true,
            arrows: false,
          },
        },
      ],
    };
  return (
    <>
    <Box id="gallery">
    
 
      <Slider {...settings}>
        {images.map(img => (
         <Box p="10px" borderWidth="1px"
         borderColor="black">
         <Image key={img} src={`${PUBLIC_URL}/${img + 1}.png`} />
         </Box>
        ))}
      </Slider>
    </Box>

  
    </>
  );
}
