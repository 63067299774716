import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Home from './Home';
import Gallery from './Gallery';
import About from './About';
import Roadmap from './Roadmap';
import FAQ from './Faq';

import { fetchData } from '../redux/data/dataActions';
import { connect } from '../redux/blockchain/blockchainActions';

const { REACT_APP_CONTRACT_ADDRESS } = process.env;

export default function Main() {
   const blockchain = useSelector(state => state.blockchain);
   const data = useSelector(state => state.data);
   const dispatch = useDispatch();

   const getData = () => {
     if (blockchain.account !== '' && blockchain.smartContract !== null) {
       dispatch(fetchData(blockchain.account));
     }
   };

   const handleConnect = () => {
     dispatch(connect());
     getData();
   };

   useEffect(() => {
     if (!blockchain.account && !blockchain.smartContract) {
      //  handleConnect();
     } else {
       getData();
     }
   }, [blockchain.account]);

  //  useEffect(() => {
  //    if (blockchain.errorMsg) {
  //      toast.error(blockchain.errorMsg);
  //    }
  //    if (data.errorMsg) {
  //      toast.error(data.errorMsg);
  //    }
  //  }, [blockchain.errorMsg, data.errorMsg]);

   const claimNFTs = count => {
     if (count <= 0) {
       return;
     }

     toast.info('Preparing your NFT...');
     const value = blockchain.web3.utils.toWei(
       (data.cost * count).toString(),
       'ether'
     );
     blockchain.smartContract.methods
       .mint(count)
       .send({
         gasLimit: '4712388',
         to: REACT_APP_CONTRACT_ADDRESS,
         from: blockchain.account,
         value,
       })
       .once('error', err => {
         console.log(err);
         toast.error('It seems the transaction was cancelled.');
       })
       .then(receipt => {
         toast.success('Woohoo! NFT minted successfully!');
         dispatch(fetchData(blockchain.account));
       });
   };
  return (
    <Box>
      <Home
        onConnect={handleConnect}
        address={blockchain.account}
        data={data}
        onMint={claimNFTs}
        loading={blockchain.loading}
      />
      <Gallery />
      <About />
      <Roadmap />
      <FAQ />
    </Box>
  );
}
