import React, { useEffect, useState } from 'react';
import { Box, Flex, Image, Text, Link, Heading, Input } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';

import { CountCard } from '../components';

export default function Home({ data, onConnect, address, onMint }) {
  const [count, setCount] = useState(1);
  const { maxSupply, totalSupply, maxMintAmount, cost, loading } = data;

  return (
    <Box
      minH="100vh"
      // bg="black"
 
      position="relative"
      backgroundImage={{ base:`${process.env.PUBLIC_URL}/mobile_bg.gif`, md:`${process.env.PUBLIC_URL}/bg.gif`}}
      backgroundPosition="center"
      backgroundSize="cover"
    >
     {/* <video id="videoBG" poster={`${process.env.PUBLIC_URL}/hom1.png`} autoPlay muted loop>
            <source src={`${process.env.PUBLIC_URL}/bg.mp4`} type="video/mp4"/>
        </video> */}
      <Flex maxW="1200px" h="100vh" flexDirection="column" justifyContent="center" alignItems="center" >
        {/* <Image
        className="home-img"
          maxW={{ base: '300px', md: '450px' }}
          mr="-190px"
          mx="auto"
          src={`${process.env.PUBLIC_URL}/Homebg1.png`}
        /> */}
        {/* <Image
          maxW={{ base: '300px', md: '450px' }}
          mx="auto"
          ml="-190px"
          src={`${process.env.PUBLIC_URL}/Homebg2.png`}
        /> */}
        <RouteLink to="mint">
          <Flex
            as="button"
            maxW="400px"
            className="box"
            py="10px"
            px="10px"
            borderRadius="5px"
            color="white"
            justifyContent="center"
            alignItems="center"
          >
            <Heading fontWeight="bold">MINT NOW</Heading>
          </Flex>
        </RouteLink>
      </Flex>
    </Box>
  );
}
