import { Flex, Text, Heading, Image, Box } from '@chakra-ui/react';

export default function About() {
  return (
    <Flex
      maxW="1200px"
      mx="auto"
      flexDirection="column"
      alignItems="center"
      py="30px"
      id="about"
      px={{ base: '20px', md: 0 }}
    >
      <Image mt="50px" src={`${process.env.PUBLIC_URL}/MONKEY.png`} />
      <Heading
        color="white"
        fontSize={{ base: '4xl', md: '6xl' }}
        fontWeight="bold"
        mt="40px"
        mb="40px"
      >
        About
      </Heading>
      <Box
        fontSize="20px"
        color="white"
        letterSpacing="3px"
        textAlign="center"
        maxW="1000px"
      >
        <Text mb="40px">
          Meta Ape Fight Club (MAFC) is a unique collection of 10 000 fighting
          apes living and training on the Ethereum Blockchain.
        </Text>

        <Text mb="40px">
          Every Apes identity is a combination of more than 200 hand drawn
          traits put together by an one-of-a-kind algorithm.
        </Text>
        <Text mb="40px">
          By owning one of these Apes you will have automatic membership to the
          MAFC with benefits such us, exclusive utilities, P2E game in the
          Metaverse, Membership to our training facility in the Metaverse and
          more.
        </Text>
      </Box>

      <Heading
        color="white"
        fontSize={{ base: '4xl', md: '6xl' }}
        fontWeight="bold"
        mt="10px"
        mb="40px"
      >
        Champion Apes
      </Heading>
      <Box
        fontSize="20px"
        color="white"
        letterSpacing="3px"
        textAlign="center"
        maxW="1000px"
      >
        <Text mb="40px">
          The Champion Apes are only 12 and will emerge as the original
          champions of all the Fighting Apes. There will only be 8 males and 4
          females. The holders of these rare NFTs will be rewarded with trips
          and 1 will win an apartment in Dubai.
        </Text>
      </Box>
    </Flex>
  );
}
