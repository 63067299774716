import React, { useEffect, useState } from 'react';
import { FaTwitter,FaInstagram  } from 'react-icons/fa';
import { SiDiscord } from 'react-icons/si';
import { Link as RouteLink, useLocation } from 'react-router-dom';

import {
  Box,
  Flex,
  Image,
  Link,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Heading,
  Text
} from '@chakra-ui/react';
import { HiMenu } from 'react-icons/hi';

import { shortenAddress } from '../candy-machine';

export default function Header({ address, onConnect }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement] = React.useState('right');
  const { pathname } = useLocation();
  const homeRoute = pathname === '/';
  return (
    <>
      <Box className="header" p="15px">
        <Flex
          maxW="1920px"
          mx="auto"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading color="white" fontSize={{ base: '3xl', md: '4xl' }}>
            Meta Ape Fight Club
          </Heading>

          <Link href="/" className="link">
            {/* <Image src={`${process.env.PUBLIC_URL}/logo.png`} w="280px" /> */}
          </Link>

          <Flex px="10px">
            {' '}
            <Link
              // as="button"
              bg="black"
              mr="10px"
              width="220px"
              h="50px"
              href="https://discord.gg/r4EbQ57mXc"
              isExternal
              color="white"
              borderRadius="5px"
              justifyContent="center"
              alignItems="center"
              display={{ base: 'none', lg: 'flex' }}
            >
              <Heading fontWeight="bold" fontSize="20px">
                JOIN DISCORD
              </Heading>
            </Link>
            <Box as="button" ml="15px" p="2" onClick={onOpen}>
              <HiMenu color="white" size="30" />
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          {/* <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader> */}
          <DrawerBody h="80vh" bg="black">
            {homeRoute && (
              <Link
                href="#about"
                className="link"
                display="block"
                color="white"
                fontWeight="bold"
                fontSize="30px"
                py="15px"
                textAlign="center"
              >
                ABOUT
              </Link>
            )}
            {homeRoute && (
              <Link
                href="#roadmap"
                className="link"
                display="block"
                color="white"
                fontWeight="bold"
                textAlign="center"
                fontSize="30px"
                py="15px"
              >
                ROADMAP
              </Link>
            )}
            {/* <Link
              href="#gallery"
              className="link"
              display="block"
              color="black"
              fontSize="18px"
              py="15px"
            >
             Gallery
            </Link> */}
            {homeRoute && (
              <Link
                href="#faq"
                textAlign="center"
                display="block"
                className="link"
                color="white"
                fontWeight="bold"
                fontSize="30px"
                py="15px"
              >
                FAQ
              </Link>
            )}
            {!homeRoute && (
              <RouteLink to="/">
                <Text
                  textAlign="center"
                  display="block"
                  className="link"
                  color="white"
                  fontWeight="bold"
                  fontSize="30px"
                  py="15px"
                >
                  Home
                </Text>
              </RouteLink>
            )}
            <RouteLink to="mint">
              <Text
                textAlign="center"
                display="block"
                className="link"
                color="white"
                fontWeight="bold"
                fontSize="30px"
                py="15px"
              >
                MINT
              </Text>
            </RouteLink>
            <RouteLink to="breading">
              <Text
                textAlign="center"
                display="block"
                className="link"
                color="white"
                fontWeight="bold"
                fontSize="30px"
                py="15px"
              >
                BREEDING
              </Text>
            </RouteLink>
            <RouteLink to="staking">
              <Text
                textAlign="center"
                display="block"
                className="link"
                color="white"
                fontWeight="bold"
                fontSize="30px"
                py="15px"
              >
                STAKING
              </Text>
            </RouteLink>
            <RouteLink to="p2egame">
              <Text
                textAlign="center"
                display="block"
                className="link"
                color="white"
                fontWeight="bold"
                fontSize="30px"
                py="15px"
              >
                P2E GAME
              </Text>
            </RouteLink>
            <Flex
              py="20px"
              mt="40px"
              px="10px"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Link
                href="https://discord.gg/r4EbQ57mXc"
                isExternal
                textAlign="center"
                color="white"
              >
                <SiDiscord size="50" />
              </Link>
              <Link
                href="https://www.twitter.com/"
                isExternal
                textAlign="center"
                color="white"
              >
                <FaTwitter size="50" />
              </Link>
              <Link
                href="https://instagram.com/meta_apefightclub_nft?utm_medium=copy_link"
                isExternal
                textAlign="center"
                color="white"
              >
                <FaInstagram size="50" />
              </Link>
              <Link
                href="https://opensea.io/Meta_Ape_Fight_Club_Official"
                isExternal
                textAlign="center"
                color="white"
              >
                <Image maxW="50px" src={`${process.env.PUBLIC_URL}/open.png`} />
              </Link>
            </Flex>
            {/* {!address ? (
              <Flex
                className="mobile-btn"
                as="button"
                bg="green.700"
                width="200px"
                h="40px"
                color="white"
                borderRadius="5px"
                justifyContent="center"
                alignItems="center"
                onClick={onConnect}
              >
                Select Wallet
              </Flex>
            ) : (
              <Box bg="green.700" px="20px" py="10px" borderRadius="5px">
                <Text color="white">{shortenAddress(address || '')}</Text>
              </Box>
            )} */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
